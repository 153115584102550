/*  scss file  */

body{
  font-size:rem-calc(18);
}

.page{
  position: relative;
  z-index: 1;

}
