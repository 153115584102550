/*  scss file  */

.content-container {
  .dubbel & {
    padding-left: 40px;
    padding-right: 40px;
  }

  padding: 20px;

  @include breakpoint(medium) {
    padding: 50px 0;
  }

  @include breakpoint(large) {
    padding: 70px 0;
  }
}

.maincontent {
  a {
    text-decoration: underline;
    color: #000;
  }
}

.left-image {
  background-position: top center;
  background-size: cover;
  min-height: 260px;

  @include breakpoint(large) {
    background-position: top right;
    min-height: inherit;
  }
}

.right-image {
  background-position: top center;
  background-size: cover;
  min-height: 260px;

  @include breakpoint(large) {
    background-position: top left;
    min-height: inherit;
  }
}

.top-image {
  min-height: 260px;
  background-position: center center;
  background-size: cover;

  @include breakpoint(large) {
    min-height: 260px;
  }
}

.greylight {
  background: $wmo_greylight;
  color: $wmo-brood;
  p,
  label,
  div,
  td {
    color: $wmo-brood;
  }
}

.grey {
  background: $wmo-grey;
  color: $wmo-white;
  p,
  label,
  div,
  td {
    color: $wmo-white;
  }
}

.greydark {
  background: $wmo_greydark;
  color: $white;
  p,
  label,
  div,
  td {
    color: $white;
  }
}
