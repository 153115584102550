/*  scss file  */

.subfooter{

  .logo-container {
    text-align: left;
    flex-grow: 1;
    padding: 10px 0;

    img{
      width:auto;
    }
  }


}
