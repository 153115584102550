.wmo-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  li {
    width: 100%;
    list-style: none;

    img {
      width: 100%;
      display: block;
    }
  }
}
