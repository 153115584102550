/* scss */
.news {
  .news-listitem {
    margin: 20px 0;
    border-bottom: 1px solid $wmo-grey;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding: 20px 0;

    @include breakpoint(medium) {
      flex-flow: row;
    }
    a {
      text-decoration: none;
      color: $wmo_greydark;
    }
    .beeld {
      width: 100%;
      margin-right: 20px;
      margin-bottom: 20px;

      @include breakpoint(medium) {
        width: 300px;
        flex-shrink: 0;
      }
    }
  }
}
