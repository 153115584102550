/*  scss file  */

$iconsize: 48px;

.footer-container {
  min-height: 270px;
  background-color: $wmo_greydark;
  color: $white;
  font-family: $header-font-family;
  border-top: solid 3px $white;

  a, a:visited{
    color:$white;
  }

  .footer-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include breakpoint(medium) {
      flex-direction: row;
    }


    .adres-container {
      flex-grow: 1;

      @include breakpoint(medium only) {
        flex-grow: 3;
      }
      .adres {
        display: flex;
        flex-direction: column;

        .naw-line {
          margin: 10px 0;
        }

      }
    }
    .smicons-container {
      flex-grow: 1;
      .smicons {
        //text-align: left;
        //right: 20px;

        .icon {
          background-color: $wmo-grey;
          color: $white;
          width: $iconsize;
          height: $iconsize;
          border-radius: $iconsize/2;
          text-align: center;
          padding-top: 6px;
          font-size: 25px;
          display: inline-block;
          margin: 0 10px;
        }
      }
    }

  }

  .box {
    padding: 20px 10px;
    //border: 1px solid #fff;

    @include breakpoint(medium) {
      padding: 20px;
    }
    @include breakpoint(large) {
      padding: 50px;
    }
  }
}


