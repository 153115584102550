/*  scss file  */

.sticky-container {
  position: fixed;
  width: 100%;
  z-index: 99;
}

.dropdown > li {
  margin-bottom: -3px;
  padding-bottom: 12px;
}

$menuspeed: 1s;

.menu-container {
  background: $wmo-grey;
  border-bottom: 1px solid $white;
  position: absolute;
  top: 0;
  transition: height $menuspeed;
  width: 100%;

  @include breakpoint(medium) {
    border-bottom: 3px solid $white;
  }

  .menu-row {
    color: #fff;
    min-height: 95px;
    transition: all $menuspeed;
    width: 100%;

    @include breakpoint(medium) {
      min-height: 156px;
    }

    .logocontainer {
      display: flex;
      justify-content: space-between;

      .frame-logo {
        height: 80px;
        margin: 10px 0;
        overflow: hidden;
        transition: height $menuspeed;
        width: 220px;
        display: flex;

        @include breakpoint(medium) {
          height: 136px;
          width: 100%;
        }

        a {
          display: flex;
          width: 100%;
        }

        #logo {
          max-width: 100%;
          // width: 140px;
          @include breakpoint(medium) {
            max-width: inherit;
            width: auto;
          }
        }

        #mobilelogo {
          max-width: 100%;
          width: auto;
          @include breakpoint(medium) {
            max-width: inherit;
            width: auto;
          }
        }
      }
    }

    .mainmenu {
      display: none;
      font-family: $header-font-family;
      font-size: rem-calc(18);
      font-weight: 500;
      height: 100%;
      //margin-top: 55px;
      transition: margin-top $menuspeed;

      @include breakpoint(large) {
        display: block;
      }

      ul {
        align-items: flex-end;
        height: 100%;
        justify-content: flex-end;
        margin: 0;
        padding: 0;

        li {
          border-bottom: 3px solid $wmo-white;

          &.selected {
            border-bottom: 3px solid $wmo-orange;
          }
        }
      }
    }
  }
}

.menu {
  a,
  a:visited {
    color: $white;
    text-transform: uppercase;
  }
}

.submenu {
  li:first-child {
    //margin-top: 15px;
  }
}

.hamburger {
  float: left;
  font-size: rem-calc(20);
  padding: 13px 25px 0 4px;

  @include breakpoint(medium) {
    font-size: rem-calc(30);
  }
  @include breakpoint(large) {
    display: none;
  }
}

//========================= small header ===================

#logo {
  display: block;
}

#mobilelogo {
  display: none;
}

.small {
  & #mobilelogo {
    display: block;
  }

  & #logo {
    display: none;
  }
}

.small .menu-row {
  min-height: 54px !important;

  .frame-logo {
    height: 53px !important;
  }

  .mainmenu {
    //margin-top: 15px !important;
  }
}

.is-dropdown-submenu > li {
  background: $wmo-grey;
}
