/*  scss file  */

.hero-container {
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  .news &{
    display:none;
  }

  &.left {
    background-position-x: left;
  }

  &.center {
    background-position-x: center;
  }

  &.right {
    background-position-x: right;
  }

  &.top {
    background-position-y: top;
  }

  &.middle {
    background-position-y: center;
  }

  &.bottom {
    background-position-y: bottom;
  }


  .hero {
    height: 275px;
    min-height: 150px;
    position: relative;


    .news-comtainer &, .news &{
      height: 0px;
      min-height: 0px;
    }
    @include breakpoint(large) {
      height: 275px;
    }

    @include breakpoint(xlarge) {
      height: 350px;
    }

    @include breakpoint(medium) {
      height: 275px;
    }

  }




  .heading {
    background-color: $wmo-grey;
    bottom: -10px;
    color: $white;
    font-family: $header-font-family;
    font-size: rem-calc(25);
    padding: 0 10px;
    position: absolute;
    text-align: center;
    width: 100%;

    @include breakpoint(medium) {
      bottom: 20px;
      font-size: rem-calc(55);
      text-align: left;
      width: auto;
    }

    @include breakpoint(large) {
      font-size: rem-calc(55);
    }
  }
}

.herospacer {
  height: 95px;

  @include breakpoint(large) {
    height: 158px;
  }

}