/*  scss file  */

$iconsize: 50px;

.cta {
  background-color: $wmo_greydark;
  border: 1px solid $wmo-grey;
  border-radius: 4px;
  color: $wmo-white !important;
  display: inline-block;
  font-family: $body-font-family;
  font-size: rem-calc(16);
  margin-top: 15px;
  padding: 10px 2px;
  text-decoration: none !important;

  &:hover {
    background-color: lighten($wmo_greydark, 25%);
    color: $wmo-white;
  }

  @include breakpoint(medium) {
    font-size: rem-calc(16);
    padding: 30px 0;
  }

  @include breakpoint(large) {
    font-size: rem-calc(16);
    padding: 10px 23px;
  }
}
