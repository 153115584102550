/*  scss file  */

.projectlist-item {
  display: flex;
  border-top: 1px solid $wmo_greylight;
  padding: 20px 0;

  a {
    color: $wmo_greydark;
    text-decoration: none;
  }

  &__image {
    padding: 0 17px 0 0;

    img {
      max-width: none;
      width: 100%;
    }
  }

  &__content {
    p {
      margin: 0;
    }
  }
  &__thema {
    font-size: rem-calc(14);
    padding: 8px 0 0 0;
    margin: 0;
  }

  &__title {
    font-size: rem-calc(26);
  }
}
