/*  scss file  */

.themalist-item {
  display: flex;
  border-top:1px solid $wmo_greylight;
  padding: 20px 0;

  a{
    color:$wmo_greydark;
  }


  &__image {
    padding: 0 ;


    img {
      max-width: none;
      width:100%;
    }
  }

  &__content {
    color: $wmo_greydark;
    position:relative;
    height:100%;

    p{
      margin:0;
    }
  }
  &__thema{
    font-size: rem-calc(14);
    padding:8px;
    margin:0;
    display: inline;
    position: absolute;
    bottom: 0;
    border: 1px solid #999;
  }

  &__title {
    font-size: rem-calc(26);
    color:$wmo_greydark;
  }
}