/*  scss file  */

.off-canvas {
  background: $wmo-grey;
  font-family: $header-font-family;
  font-weight: 500;
  font-size: rem-calc(18);
  .selected {
    border-left: 3px solid $white;
  }
  .close-button{
    float:right;
    color:$white;
    padding:5px;
    font-size: rem-calc(25);
  }
  .is-drilldown{
    width:100%;
  }
  .js-drilldown-back > a::before {
    content:' ';
    margin-right:0;
   border:none;
  }
}