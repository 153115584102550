/*  scss file  */

.langtoggle-container {
  position: absolute;
  height: 30px;
  right: 10px;
  top: 1px;
  text-align: right;
  display: flex;
  padding: 2px 10px;
  border-radius: 15px;
  font-size: 12px;
  display: block;
  color: $white;

  a {
    color: $white;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    padding: 2px;
    // width: 20px;
    // height: 20px;
    // display: block;
    padding: 3px 5px;
    white-space: nowrap;
  }

  .langtoggle {
    align-self: right;
    white-space: nowrap;
    display: block;

    .selected {
      background: $white;
      color: $wavs-blauwdonker;

    }

  }
}