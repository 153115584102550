/*  scss file  */

.position-grid{

  TD,TR{
    margin:0;
    padding:0;
  }
  TD{
    padding:0 6px;
  }

  input{
    margin:0;
    padding:0;
  }

  margin:0;
  padding:0;
}